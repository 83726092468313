import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { NavigationMenuItems } from 'src/app/model/menu-items';
import { Router } from '@angular/router';

@Component({
  selector: 'app-full-screen-menu',
  templateUrl: './full-screen-menu.component.html',
  styleUrls: ['./full-screen-menu.component.scss']
})
export class FullScreenMenuComponent implements OnInit {

  constructor(public router: Router) { }

  @Output()
  public close: EventEmitter<any> = new EventEmitter<any>();

  navigationMenuItems = NavigationMenuItems;

  ngOnInit(): void {
  }

}
