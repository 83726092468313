<div class="container-fluid">
    <span class="heading">Le recensioni</span>
    <bar-rating class="bar-rating-custom" [theme]="'fontawesome-o'" [rate]="mediaPunteggio" [max]="5" [readOnly]="'true'"></bar-rating>
    <p>Punteggio basato su {{recensioni.length}} recensioni.</p>
    <hr style="border:3px solid #f1f1f1">

    <div class="row p-2">
        <div class="side">
            <div>5 stelle</div>
        </div>
        <div class="middle">
            <div class="bar-container">
                <div class="bar-5" style.width="{{(totale5Stelle/recensioni.length * 100) + '%'}}"></div>
            </div>
        </div>
        <div class="side right">
            <div>{{totale5Stelle}}</div>
        </div>
        <div class="side">
            <div>4 stelle</div>
        </div>
        <div class="middle">
            <div class="bar-container">
                <div class="bar-4" style.width="{{(totale4Stelle/recensioni.length * 100) + '%'}}"></div>
            </div>
        </div>
        <div class="side right">
            <div>{{totale4Stelle}}</div>
        </div>
        <div class="side">
            <div>3 stelle</div>
        </div>
        <div class="middle">
            <div class="bar-container">
                <div class="bar-3" style.width="{{(totale3Stelle/recensioni.length * 100) + '%'}}"></div>
            </div>
        </div>
        <div class="side right">
            <div>{{totale3Stelle}}</div>
        </div>
        <div class="side">
            <div>2 stelle</div>
        </div>
        <div class="middle">
            <div class="bar-container">
                <div class="bar-2" style.width="{{(totale2Stelle/recensioni.length * 100) + '%'}}"></div>
            </div>
        </div>
        <div class="side right">
            <div>{{totale2Stelle}}</div>
        </div>
        <div class="side">
            <div>1 stelle</div>
        </div>
        <div class="middle">
            <div class="bar-container">
                <div class="bar-1" style.width="{{(totale1Stelle/recensioni.length * 100) + '%'}}"></div>
            </div>
        </div>
        <div class="side right">
            <div>{{totale1Stelle}}</div>
        </div>
    </div>
</div>

<br />

<div>

    <div *ngFor="let r of recensioni" class="row">

        <div class="col-4">
            <div>{{r.user !== null && r.user != '' ? r.user : 'Anonimo'}}</div>
            <div>{{r.date | date: 'dd/MM/yyyy' }}</div>
        </div>
        <div class="col-8">
            <div>
                <bar-rating class="bar-rating-custom" [theme]="'fontawesome-o'" [rate]="r.rating" [max]="5"
                    [readOnly]="'true'"></bar-rating>
            </div>
            <div>{{r.content}}</div>
        </div>

    </div>

</div>