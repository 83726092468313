<div class="fullscreen-menu animate__animated animate__fadeIn delay-500ms">
    <div class="close-btn">
        <i class="fa fa-close" style="margin-right: 22px; cursor: pointer;" (click)="close.emit()"></i>
    </div>
    <div *ngFor="let e of navigationMenuItems" class="fullscreen-nav-item animated zoomIn faster">
        <a class="nav-link" 
        angulartics2On="click" 
        angularticsAction="Chiamata"
        [angularticsCategory]="'Conversione'"
        [href]="e.link"
        [target]="e.target"
        [ngClass]="{ 'active': e.isActive(router) === true }"> <i class="fa {{e.icon}}"></i> {{e.title}}</a>
    </div>
</div>

