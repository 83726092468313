import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Recensione } from '../model/recensione';

@Injectable({
  providedIn: 'root'
})
export class RecensioniService {

  constructor(private http: HttpClient) { }

  getRecensioniOperatore(codOperatore) {
    return this.http.get<Recensione[]>(environment.restEndpoint + '/app/recensioni/' + codOperatore);
  }

  inserisciRecensione(recensione) {
    return this.http.post<any>(environment.restEndpoint + '/app/recensioni', recensione)
  }

  getTestimonials() {
    return this.http.get<Recensione[]>(environment.restEndpoint + '/app/recensioni/testimonials/');
  }

}
