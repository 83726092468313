import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Promozione } from '../model/promozione';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PromozioniService {

  constructor(private httpClient: HttpClient) { }

  public getPromozioni() {
    return this.httpClient.get<Promozione[]>(environment.restEndpoint + '/app/promozioni/list');
  }
}
