import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HomeComponent } from './home/home/home.component';
import { AmoreComponent } from './pagine/amore/amore.component';
import { CartomantiComponent } from './pagine/cartomanti/cartomanti.component';
import { InfoPrezziComponent } from './pagine/info-prezzi/info-prezzi.component';
import { OroscopoComponent } from './pagine/oroscopo/oroscopo.component';
import { OroscopoDetailComponent } from './pagine/oroscopo-detail/oroscopo-detail.component';
import { PromozioniComponent } from './pagine/promozioni/promozioni.component';
import { WelcomeComponent } from './pagine/welcome/welcome.component';
import { DefaultPageComponent } from './layout/default-page/default-page.component';
import { BottomBarComponent } from './layout/default-page/bottom-bar/bottom-bar.component';
import { TopbarComponent } from './layout/default-page/topbar/topbar.component';
import { ZodiacSignSelectorComponent } from './components/zodiac-sign-selector/zodiac-sign-selector.component';
import { CookieService } from 'ngx-cookie-service';
import { BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { LOCALE_ID } from '@angular/core';
import localeIt from '@angular/common/locales/it';
import { registerLocaleData } from '@angular/common';
import { itLocale } from 'ngx-bootstrap/locale';
import { Angulartics2Module } from 'angulartics2';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RegistrazioneComponent } from './registrazione/registrazione.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SplashscreenComponent } from './splash/splashscreen/splashscreen.component';
import { RequestInterceptorService } from './service/request-interceptor.service';
import { TurniCartomantiComponent } from './pagine/turni-cartomanti/turni-cartomanti.component';
import { TabellaTurniComponent } from './components/tabella-turni/tabella-turni.component';
import { PaypalComponent } from './pagine/paypal/paypal.component';
import { FullScreenMenuComponent } from './layout/full-screen-menu/full-screen-menu.component';
import { PaginaCartomanteComponent } from './pagine/pagina-cartomante/pagina-cartomante.component';
import { NgxSocialShareModule } from 'ngx-social-share';
import { SafePipe } from './util/safe.pipe';
import { TruncatePipe } from './util/truncate.pipe';
import { ArticoloComponent } from './pagine/articolo/articolo.component';
import { ArticoliComponent } from './pagine/articoli/articoli.component';
import { InserimentoRecensioneComponent } from './components/inserimento-recensione/inserimento-recensione.component';
import { RecensioniOperatoreComponent } from './components/recensioni-operatore/recensioni-operatore.component';
import { BarRatingModule } from "ngx-bar-rating";
import { NgxInputStarRatingModule } from 'ngx-input-star-rating';




defineLocale('it', itLocale);
registerLocaleData(localeIt);

@NgModule({
  declarations: [
    AppComponent,
    ZodiacSignSelectorComponent,
    HomeComponent,
    AmoreComponent,
    CartomantiComponent,
    InfoPrezziComponent,
    OroscopoComponent,
    OroscopoDetailComponent,
    PromozioniComponent,
    WelcomeComponent,
    DefaultPageComponent,
    BottomBarComponent,
    TopbarComponent,
    RegistrazioneComponent,
    SplashscreenComponent,
    TurniCartomantiComponent,
    TabellaTurniComponent,
    PaypalComponent,
    FullScreenMenuComponent,
    PaginaCartomanteComponent,
    SafePipe,
    TruncatePipe,
    ArticoloComponent,
    ArticoliComponent,
    InserimentoRecensioneComponent,
    RecensioniOperatoreComponent,

  ],
  imports: [
    BrowserModule,
    NgxInputStarRatingModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    BarRatingModule,
    BsDatepickerModule.forRoot(),
    Angulartics2Module.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    NgxSocialShareModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  schemas: [],
  providers: [CookieService,
    { provide: LOCALE_ID, useValue: 'it-IT' },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptorService, multi: true }],
  bootstrap: [AppComponent]

})
export class AppModule { }
