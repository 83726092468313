import { Component, NgZone, OnInit, OnDestroy } from '@angular/core';
import { OroscopoEntry } from 'src/app/model/oroscopo-entry';
import { OroscopoService } from 'src/app/service/oroscopo.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SegnoZodiacale } from 'src/app/model/segno-zodiacale';
import { NavigationService } from 'src/app/service/navigation.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-oroscopo-detail',
  templateUrl: './oroscopo-detail.component.html',
  styleUrls: ['./oroscopo-detail.component.scss']
})
export class OroscopoDetailComponent implements OnInit, OnDestroy {

  oroscopoEntries: OroscopoEntry[];
  segnoZodiacale: SegnoZodiacale;
  backButtonSub2: Subscription;
  signId: number;
  dataAttuale: Date = new Date();

  constructor(
    private zone: NgZone,
    public router: Router,
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute,
    private oroscopoService: OroscopoService,
    public ngxUiLoader: NgxSpinnerService) { }

  loadComplete = false;

  backButtonSub: Subscription;
  routerParamsSub: Subscription;



  subEventoIndietro: Subscription
  mostraRisultato = false;

  ngOnInit() {
    this.routerParamsSub = this.activatedRoute.queryParams.subscribe(params => {
      // tslint:disable-next-line: no-string-literal
      
      const id = params['id'];
      this.signId = id;
      this.segnoZodiacale = this.oroscopoService.getSegniZodiacali().find(e => e.zodiacSignId + '' == this.signId + '');
      //mostra spinner
      this.ngxUiLoader.show()
      this.oroscopoService.getOroscopoEntries().subscribe((data: OroscopoEntry[]) => {
      //hide spinner
      this.ngxUiLoader.hide()
        if (data) {
          this.oroscopoEntries = data.filter(e => e.zodiacSignId == id);
        }
        this.loadComplete = true;
        
      });
    });
    
    this.subEventoIndietro = this.navigationService.eventoIndietro.subscribe(x => {
      if (this.mostraRisultato == true) {
        this.mostraRisultato = false
      } else {
        this.router.navigate(['oroscopo']);
      }
    })
  }

  ngOnDestroy(){
    if (this.backButtonSub) {
      this.backButtonSub.unsubscribe();
    }
    if (this.routerParamsSub) {
      this.routerParamsSub.unsubscribe();
    }
    if (this.backButtonSub2) {
      this.backButtonSub2.unsubscribe();
    }
    this.loadComplete = false;

    this.subEventoIndietro.unsubscribe()
  }

 // share() {
 //   let oroscopoText = 'Ecco il mio oroscopo NewStart (' + this.segnoZodiacale.zodiacSignName + ')\n';
 //   this.oroscopoEntries.forEach((e: OroscopoEntry) => {
 //     oroscopoText += e.categoryName + ': ' + e.content + '\n';
 //   });
 //   this.socialSharing.share(oroscopoText);
 // }

  getIconStyle(entry) {
    return {
      color: entry.categoryIconColor,
      'font-size': '6.5vw',
      'margin-right': '2.5vw',
    };
  }

}
