import { Component, OnInit, Input } from '@angular/core';
import { DettaglioOperatore } from 'src/app/model/dettaglio-operatore';
import { RecensioniService } from 'src/app/service/recensioni.service';

@Component({
  selector: 'app-inserimento-recensione',
  templateUrl: './inserimento-recensione.component.html',
  styleUrls: ['./inserimento-recensione.component.scss']
})
export class InserimentoRecensioneComponent implements OnInit {

  commento = '';
  nickname = '';
  punteggio = null;

  minimoCaratteri = 30;
  massimoCaratteri = 500;
  inviato = false;
  flgError = false;
  msg = '';

  constructor(
    private recensioniService: RecensioniService
    ) { }

  @Input() operatore: DettaglioOperatore;

  ngOnInit(): void {
  }

  abilitaPulsante() {
    const punteggioInserito = this.punteggio != null && this.punteggio >= 1 && this.punteggio <= 5;
    const contenutoValido = this.commento != '' && this.commento.length >= 30 && this.commento.length <= 500;
    return punteggioInserito && contenutoValido;
  }

  scrivi() {
    this.msg ='';
    this.flgError = false;
    this.recensioniService.inserisciRecensione({
      user: this.nickname,
      rating: this.punteggio,
      codiceOperatore: this.operatore.codice,
      content: this.commento
    }).subscribe((esito) => {
      this.inviato = true;
      this.msg = 'Grazie, abbiamo ricevuto la tua recensione, verrà pubblicata non appena verrà approvata da un moderatore.';
    }, err => {
      this.flgError = true;
      this.msg = 'Ci dispaice ma l\'operazione non è andata a buon fine';
    });

  }

}
