import { Component, OnInit, OnDestroy, PLATFORM_ID, Inject } from '@angular/core';
import { OperatoriService } from 'src/app/service/operatori.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DettaglioOperatore } from 'src/app/model/dettaglio-operatore';
import { StatoOperatore } from 'src/app/model/stato-operatore';
import { statoOperatoreLibero, statoOperatoreOccupato, statoOperatoreOffline, statoOperatorePrenotato, statoOperatorePausa } from 'src/app/components/stato-operatori/stato-operatori.component';
import { timer, Subscription } from 'rxjs';
import { TurniService } from 'src/app/service/turni.service';
import { TurnoOperatore } from 'src/app/model/turno-operatore';
import { TurniOperatore } from 'src/app/model/turni-operatore';
import { isPlatformBrowser } from '@angular/common';
import { StatoOperatoreStyle } from '../cartomanti/cartomanti.component';
import { Operatore } from 'src/app/model/operatore';
import { NavigationService } from 'src/app/service/navigation.service';
import { ErrorHandlingService } from 'src/app/service/error-handling.service'

@Component({
  selector: 'app-pagina-cartomante',
  templateUrl: './pagina-cartomante.component.html',
  styleUrls: ['./pagina-cartomante.component.scss']
})
export class PaginaCartomanteComponent implements OnInit, OnDestroy {

  constructor(
    private turniService: TurniService,
    private activedRoute: ActivatedRoute,
    private operatoriService: OperatoriService,
    private navigationService: NavigationService,
    private errorHandlingService: ErrorHandlingService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformID: any) { }

  operatori: Operatore[];
  dettaglioOperatore?: DettaglioOperatore;
  statoOperatore: StatoOperatore;
  statoSub: Subscription;
  mostraPannelloChiamata = false;
  

  loadComplete = false;

  turniOperatore: TurniOperatore[];

  ngOnInit() {
    /* Il codice dentro alle graffe viene eseguito quando si apre la pagina 'e' sono i parametri della url (tipo 'ginevra') */
    this.activedRoute.params.subscribe(e => {
      /* Chiama il servizio e mette dentro dettaglioOperatore i dati che ha ricevuto */
      this.operatoriService.getDettaglioOperatore(e.nickName).subscribe((operatoreData: DettaglioOperatore) => {
        this.dettaglioOperatore = operatoreData;

        this.turniService.getTurni().subscribe((turniData: TurniOperatore[]) => {
          this.turniOperatore = turniData.filter(t => t.codice == operatoreData.codice);
          this.loadComplete = true;
        });
        if (isPlatformBrowser(this.platformID)) {
          this.statoSub = timer(0, 15 * 1000).subscribe((n) => {
            this.operatoriService.getStatoOperatori().subscribe((stati: StatoOperatore[]) => {
              this.statoOperatore = stati.find(J => +J.codenumber === +this.dettaglioOperatore.codice);
            });
          });
        }

      });
    });
    this.subEventoIndietro = this.navigationService.eventoIndietro.subscribe(x => {
      //alert(this.mostraPannelloChiamata)
      if (this.mostraPannelloChiamata == true) {
        this.mostraPannelloChiamata = false
      } else {
        this.router.navigate(['cartomanti']);
      }
    })
  }


  chiama() {

    setTimeout(() => {
      this.mostraPannelloChiamata = true;
    }, 250);

  }

  dimissPanel() {
    this.mostraPannelloChiamata = false;
  }

  getStato() {
    if (statoOperatoreLibero.includes(this.statoOperatore.state)) {
      return 'Disponibile';
    } else if (statoOperatoreOccupato.includes(this.statoOperatore.state)) {
      if (this.statoOperatore.currentqueue !== '') {
        return 'Occupato con ' + this.statoOperatore.currentqueue.toString().toLowerCase();
      } else {
        return 'Occupato';
      }
    } else if (statoOperatoreOffline.includes(this.statoOperatore.state)) {
      return 'Offline';
    } else if (statoOperatorePrenotato.includes(this.statoOperatore.state)) {
      return 'Prenotato';
    } else if (statoOperatorePausa.includes(this.statoOperatore.state)) {
      return 'Pausa';
    }
  }

  getStyle() {
    if (statoOperatoreLibero.includes(this.statoOperatore.state)) {
      return {
        'border-color': '#28a745',
        'color': '#28a745',
      };
    } else if (statoOperatoreOccupato.includes(this.statoOperatore.state)) {
      return {
        'border-color': '#ff0000',
        'color': '#ff0000'
      };
    } else if (statoOperatoreOffline.includes(this.statoOperatore.state)) {
      return {
        'border-color': '#999',
        'color': '#999'
      };
    } else if (statoOperatorePrenotato.includes(this.statoOperatore.state)) {
      return {
        'border-color': '#ff0000',
        'color': '#ff0000'
      };
    } else if (statoOperatorePausa.includes(this.statoOperatore.state)) {
      return {
        'border-color': 'orange',
        'color': 'orange'
      };
    }
  }

  scaricaOperatoriSub: Subscription;
  backButtonSub: Subscription;
  backButtonSub2: Subscription;
  subscription: Subscription;

  ngOnDestroy() {
    if (this.statoSub) {
      this.statoSub.unsubscribe();
    }
    if (this.scaricaOperatoriSub) {
      this.scaricaOperatoriSub.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.backButtonSub2) {
      this.backButtonSub2.unsubscribe();
    }
    this.operatori = [];
    this.loadComplete = false;

    this.subEventoIndietro.unsubscribe()
  }
  naviga(pagina) {
    this.router.navigate([pagina]);
  }
  subEventoIndietro: Subscription

  goBack() {
    if (this.mostraPannelloChiamata == true) {
      this.mostraPannelloChiamata = false;
    } else {
      //TOrna home
    }
  }

}
