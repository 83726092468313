import { Component } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Angulartics2Module } from 'angulartics2';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'newstart-pwa';

  constructor(localeService: BsLocaleService,
     angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics) {
    localeService.use('it');

  }

}
