<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate" [fullScreen]="true">
    <p style="color: white; font-weight: 500; font-size: 24px;"> Caricamento... </p>
    </ngx-spinner>

    <app-topbar></app-topbar>

    <div class="content-wrapper">
        <router-outlet></router-outlet>
    </div>

    <app-bottom-bar *ngIf="showMenu()"></app-bottom-bar>