import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ErrorHandlingService } from './error-handling.service';


export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable()
export class RequestInterceptorService implements HttpInterceptor {

    constructor(private errorHandlingService: ErrorHandlingService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap(
                (event: HttpEvent<any>) => { },
                (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        this.errorHandlingService.handleError(err);
                    }
                }),
        );
    }


}
