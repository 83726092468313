<div class="container-fluid container-custom animate__animated animate__fadeIn delay-500ms" *ngIf="loadComplete">

    <div class="ion-card-oroscopo" style="margin-top: 3vw;">
        <div>
            <div class="oroscopo-detail-wrapper">
                <div class="oroscopo-sign-image">
                    <div class="card-title" style="text-transform: uppercase; color: white; font-size: 6vw; width: 100%;">{{segnoZodiacale.zodiacSignName}}
                    <img class="oroscopo-card-image" src='{{segnoZodiacale.image}}' />
                    <div class="oroscopo-data" style="text-transform: uppercase">{{ dataAttuale | date :'fullDate' }}</div>

                </div>
             <!--   <div class="oroscopo-sign-details" (click)="share()">
                  <div class="card-subtitle" style="color: white; font-size: 3vw;">CONDIVIDI OROSCOPO</div>  
                   -->
                
                </div>
            </div>
        </div>
    </div>

    <div class="card-oroscopo" *ngFor="let oroscopoEntry of oroscopoEntries">
        <div class="card-header" style="padding-bottom: 0px;">
            <div class="card-title-2" style="font-size: 8vw; text-align: center; margin-bottom: 1vw; color: white;">

                <i class="fa {{oroscopoEntry.categoryIcon}}" [ngStyle]="getIconStyle(oroscopoEntry)"></i>{{ oroscopoEntry.categoryName }}
            </div>
           <!-- <bar-rating [readOnly]="true" style="display: flex; justify-content: center;" [theme]="'stars'" [(rate)]="oroscopoEntry.strength" [max]="5"></bar-rating> -->
        </div>
        <div [innerHTML]="oroscopoEntry.content" class="card-content" style=" font-size: 4.5vw; color: white;">
            {{ oroscopoEntry.content }}
        </div>
    </div>
</div>