import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from 'src/app/service/navigation.service';

@Component({
  selector: 'app-bottom-bar',
  templateUrl: './bottom-bar.component.html',
  styleUrls: ['./bottom-bar.component.scss']
})
export class BottomBarComponent implements OnInit {

  constructor(public router: Router,
    public navigationService: NavigationService) { }

  naviga(pagina) {
    this.router.navigate([pagina]);
  }

  ngOnInit(): void {
  }

}
