import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StatoOperatore } from '../model/stato-operatore';
import { environment } from 'src/environments/environment';
import { Operatore } from '../model/operatore';

@Injectable({
  providedIn: 'root'
})
export class OperatoriService {

  constructor(public httpClient: HttpClient) { }

  public getListaOperatori() {
    return this.httpClient.get<Operatore[]>(environment.restEndpoint + '/app/operatori/listaOperatori');
  }

  public getStatoOperatori() {
    return this.httpClient.get<StatoOperatore[]>(environment.restEndpoint + '/app/operatori/statoOperatori');
  }

}