import { Component, OnInit } from '@angular/core';
import { ArticoliService } from 'src/app/service/articoli.service';
import { Router } from '@angular/router';
import { Articolo } from 'src/app/model/articolo';

@Component({
  selector: 'app-articoli',
  templateUrl: './articoli.component.html',
  styleUrls: ['./articoli.component.scss']
})
export class ArticoliComponent implements OnInit {

  constructor(private articoliService: ArticoliService, private router: Router) { }

  articoli: Articolo[] = []

  ngOnInit(): void {
    this.articoliService.getArticoli().subscribe(data => {
      this.articoli = data;
    });
  }

  gotoDettaglio(elemento) {
    this.router.navigate(['/articolo/' + elemento.slug]);
  }

}
