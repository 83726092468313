<div class="container-fluid" style="margin-bottom: 4.5rem;">

    <div class="card-vertical-align">
        <div (click)="naviga('oroscopo')" class="card-custom-1 card-oroscopo animate__animated animate__fadeIn"></div>
        <div (click)="naviga('amore')" class="card-custom-1 card-coppia  animate__animated animate__fadeIn delay-250ms">
        </div>
        <div (click)="naviga('cartomanti')"
            class="card-custom-1 card-cartomanti  animate__animated animate__fadeIn delay-500ms"></div>
        <div (click)="naviga('articoli')"
            class="card-custom-1 card-promo  animate__animated animate__fadeIn delay-750ms"></div>
        <div (click)="naviga('info-prezzi')"
            class="card-custom-1 card-prezzi  animate__animated animate__fadeIn delay-1000ms"></div>
    </div>

</div>