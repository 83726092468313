    <div class="input-style">
        <div class="row no-gutters">
            <div class="col-12 text-center">
                <p class="p-style">Inserisci nome Cartomante</p>
                <input id="inputRicerca" placeholder="Cerca Turni..." type="text" class="form-control"
                    (keyup)="filtra($event)" style="width: auto; ">
            </div>
        </div>
    </div>

    <div *ngIf="!loadComplete" style="margin-top: 2rem;">
        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate"
            [fullScreen]="true">
            <p style="color: white; font-weight: 500; font-size: 24px;"> Caricamento... </p>
        </ngx-spinner>
    </div>

    <app-tabella-turni *ngIf="loadComplete" [turniOperatori]="datiFiltati"></app-tabella-turni>

