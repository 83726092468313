<div class="container">
    <br />
    <div *ngFor="let elemento of articoli" class="card p-1 m-1">
        <div class="card-title ml-4 mt-4 mb-0">
            <h2 style="cursor: pointer;" (click)="gotoDettaglio(elemento)">{{elemento.titolo}}</h2>
        </div>

        <div class="card-body">
            <div class="op-img" style="display: flex; justify-content: center; align-items: center; flex-direction: column-reverse;">
                <img style="height: auto;" class="img-fluid" src="{{elemento.fotoBase64}}" />
            </div>

            <div style="flex-grow: 1; padding: 0px 32px 0px 32px;">
                <div [innerHTML]="elemento.contenuto | truncate : 512 "></div>
            </div>

            
        </div>

        <button class="btn btn-custom mb-4" style="max-width: 128px; margin-left: auto;" (click)="gotoDettaglio(elemento)">Leggi di più</button>

        <div class="card-footer" style="background: none;">
            <p><i>Articolo pubblicato in data {{elemento.publishDate | date: 'dd/MM/yyyy'}}</i></p>
        </div>

    </div>
</div>