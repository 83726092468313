import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavigationService } from 'src/app/service/navigation.service';

export interface InfoPrezziEntry {
  operatore: string;
  scattoRisposta: string;
  importoMinuto: string;
  operatoreImg: string;
}

@Component({
  selector: 'app-info-prezzi',
  templateUrl: './info-prezzi.component.html',
  styleUrls: ['./info-prezzi.component.scss'],
})
export class InfoPrezziComponent implements OnInit {

  backButtonSub: Subscription;
  backButtonSub2: Subscription;

  loadComplete = false;
  mostraRisultato = false;

  constructor(private zone: NgZone,
    private navigationService: NavigationService) { }

  prezzi: InfoPrezziEntry[] = [
    {
      operatore: 'Telecom',
      scattoRisposta: '0,10000',
      importoMinuto: '1,22000',
      operatoreImg: '/assets/telecom.png',
    },
    {
      operatore: 'Tim',
      scattoRisposta: '0,12910',
      importoMinuto: '1,26014',
      operatoreImg: '/assets/tim.png',
    },
    {
      operatore: 'Vodafone',
      scattoRisposta: '0,12910',
      importoMinuto: '1,26014',
      operatoreImg: '/assets/vodafone.png',
    },
    {
      operatore: 'Wind',
      scattoRisposta: '0,12500',
      importoMinuto: '1,28100',
      operatoreImg: '/assets/wind.png',
    },
    {
      operatore: 'Illiad',
      scattoRisposta: '0,12910',
      importoMinuto: '1,26014',
      operatoreImg: '/assets/iliad.png',
    },
    {
      operatore: '3',
      scattoRisposta: '0,12910',
      importoMinuto: '1,28100',
      operatoreImg: '/assets/3.png',
    },
  ];
  subEventoIndietro: Subscription

 ngOnDestroy() {
  this.subEventoIndietro.unsubscribe()
 }

  // goBack() {
  //   this.nativePageTransitions.fade(null);
  //   this.zone.run(async () => {
  //     await this.navCtrl.navigateRoot('/home');
  //   });
  // }

  ngOnInit() {
  //  this.backButtonSub = this.platform.backButton.subscribe(evt => {
  //    this.goBack();
  //  });
  //  this.backButtonSub2 = this.navigationService.goBackEvent.subscribe(evt => {
  //    this.goBack();
  //  });
  this.subEventoIndietro = this.navigationService.eventoIndietro.subscribe(x => {
    if (this.mostraRisultato == true) {
      this.mostraRisultato = false
    } else {
      this.navigationService.goToHome()
    }
  });
  }

}
