import { Component, NgZone, OnInit, OnDestroy } from '@angular/core';
import { PromozioniService } from 'src/app/service/promozioni.service';
import { Promozione } from 'src/app/model/promozione';
import { Subscription } from 'rxjs';
import { NavigationService } from 'src/app/service/navigation.service';

@Component({
  selector: 'app-promozioni',
  templateUrl: './promozioni.component.html',
  styleUrls: ['./promozioni.component.scss'],
})
export class PromozioniComponent implements OnInit, OnDestroy {

  promozioni: Promozione[];
  backButtonSub: Subscription;
  backButtonSub2: Subscription;
  loadComplete = true;

  constructor(
    private promozioniService: PromozioniService,
    private navigationService: NavigationService,
    private zone: NgZone,
  ) { }


  subEventoIndietro: Subscription
  ngOnDestroy() {
    this.subEventoIndietro.unsubscribe()
  }

  // goBack() {
  //   this.nativePageTransitions.fade(null);
  //   this.zone.run(async () => {
  //     await this.navCtrl.navigateRoot('/home');
  //   });
  // }
  mostraRisultato = false;

  ngOnInit() {
    this.promozioniService.getPromozioni().subscribe((data: Promozione[]) => {
      this.promozioni = data;
    });
    this.subEventoIndietro = this.navigationService.eventoIndietro.subscribe(x => {
      if (this.mostraRisultato == true) {
        this.mostraRisultato = false
      } else {
        this.navigationService.goToHome()
      }
    })
  }

}
