<div *ngIf="loadComplete" class="container-fluid animate__animated animate__fadeIn delay-500ms">

    <div class="card-custom" *ngFor="let promozione of promozioni">
        <div class="card-title">{{promozione.title}}</div>
        <div>
            <div class="card-content" style="margin-bottom: 3vw;">
                <img [src]="promozione.imageBase64" class="img-style rounded" style="width: 100%" />
            </div>
            <div class="content">
                {{promozione.content}}
            </div>
        </div>
    </div>
</div>