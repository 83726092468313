<div class="container">
    <span class="heading">Scrivi cosa pensi di {{operatore.nickName}}</span>
    <br /> <br />

    <div *ngIf="!inviato===true">
        <div class="row">
            <div class="col-md-2">
                Scegli un nome
            </div>
            <div class="col-md-10">
                <input [(ngModel)]="nickname" class="form-control" placeholder="Anonimo" style="width: 256px;" max="30" />
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-12">
                <bar-rating class="bar-rating-custom" [theme]="'fontawesome-o'" [(rate)]="punteggio" [max]="5"></bar-rating>
            </div>
            <div class="col-md-12">
                <textarea [(ngModel)]="commento" id="comment" cols="40" rows="4" class="form-control" maxlength="500"
                    placeholder="Scrivi qui..."></textarea>
                <p>{{ (massimoCaratteri - commento?.length) }} caratteri rimanenti</p>
            </div>
        </div>
        <div class="m-4" style="display: flex; justify-content: center;">
            <button class="btn btn-primary" [disabled]="!abilitaPulsante()" (click)="scrivi()">Invia recensione</button>
        </div>
    </div>


    <div *ngIf="inviato === true" class="alert alert-primary" role="alert">
        {{msg}}
    </div>

    <div *ngIf="flgError === true" class="alert alert-danger" role="alert">
        {{msg}}
    </div>

</div>