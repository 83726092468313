import { Component, OnInit, HostListener, Input } from '@angular/core';
import { TurnoOperatore } from 'src/app/model/turno-operatore';

@Component({
  selector: 'app-tabella-turni',
  templateUrl: './tabella-turni.component.html',
  styleUrls: ['./tabella-turni.component.scss']
})
export class TabellaTurniComponent implements OnInit {

  nessunRisultato = 'Spiacente, la ricerca non ha prodotto risultati';

  @Input() turniOperatori;

  public innerWidth: any;

  constructor() { }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
  }

  // Chiamato dalla pagina HTML, stampa solo i turni con giorno specificato
  getTurniGiorno(turni: TurnoOperatore[], giorno: number) {
    const turniGiorno = turni.filter(turno => turno.idGiorno === giorno);
    let risultato = '';
    for (const turno of turniGiorno) {
      risultato = risultato + (turno.oraInizio + '-' + turno.oraFine) + '<br>';
    }
    return risultato;
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }
}
