import { Component, NgZone, OnInit, OnDestroy } from '@angular/core';
import { OperatoriService } from 'src/app/service/cartomanti.service';
import { StatoOperatore } from 'src/app/model/stato-operatore';
import { interval, Subscription, timer } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { Operatore } from 'src/app/model/operatore';
import { NavigationService } from 'src/app/service/navigation.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { stringify } from 'querystring';

export interface StatoOperatoreStyle {
  stati: string[];
  description: string;
  css: any;
  textClass: any;
  callBtnEnabled: boolean;
}


export const STATO_PRENOTATA: StatoOperatoreStyle = {
  stati: ['OutBound'],
  description: 'Prenotata',
  css: {
    color: 'orange',
  },
  textClass: '',
  callBtnEnabled: true,
}

export const STATO_OCCUPATO: StatoOperatoreStyle = {
  stati: ['After Call Working', 'Call transfer', 'Answered'],
  description: 'Occupato',
  css: {
    color: 'red',
  },
  textClass: 'animated flash infinite slow',
  callBtnEnabled: true,
}

export const STATO_CHIAMA: StatoOperatoreStyle = {
  stati: ['Busy Channel', 'Idle'],
  description: 'Chiama',
  css: {
    color: '#37bd20',
  },
  textClass: '',
  callBtnEnabled: true,
}

export const STATO_PAUSA: StatoOperatoreStyle = {
  stati: ['StandBy', 'Pause'],
  description: 'Pausa',
  css: {
    color: 'orange',
  },
  textClass: '',
  callBtnEnabled: true,
}

export const STATO_OFFLINE: StatoOperatoreStyle = {
  stati: ['Logoff'],
  description: 'Offline',
  css: {
    color: '#b3b5b8',
  },
  textClass: '',
  callBtnEnabled: true,
}

export const statiOperatoriStyle: StatoOperatoreStyle[] = [
  STATO_PRENOTATA,
  STATO_OCCUPATO,
  STATO_CHIAMA,
  STATO_PAUSA,
  STATO_OFFLINE,
];

@Component({
  selector: 'app-cartomanti',
  templateUrl: './cartomanti.component.html',
  styleUrls: ['./cartomanti.component.scss'],
})
export class CartomantiComponent implements OnInit, OnDestroy {

  operatori: Operatore[];
  mostraPannelloChiamata = false;
  scaricaOperatoriSub: Subscription;
  backButtonSub: Subscription;
  backButtonSub2: Subscription;
  loadComplete = false;
  servizioCortesiaPhone = '06.995.40.955';
  chiamaDirettamentePhone = '899.77.50.15'; 
  ricaricaConCartaPhone = '0695544491';

  constructor(
    private operatoriService: OperatoriService,
    private navigationService: NavigationService,
    public ngxUiLoader: NgxSpinnerService,
    public router: Router, ) { }

  subscription: Subscription;

  ngOnDestroy() {
    if (this.scaricaOperatoriSub) {
      this.scaricaOperatoriSub.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.backButtonSub2) {
      this.backButtonSub2.unsubscribe();
    }
    this.operatori = [];
    this.loadComplete = false;

    this.subEventoIndietro.unsubscribe()
  }

  mostraRisultato = false;

  gotoOperatore(operatore) {
    this.router.navigate(['/cartomante/' + operatore.nickName]);
  }

  ngOnInit() {
    this.ngxUiLoader.show()
    this.scaricaOperatoriSub = this.operatoriService.getListaOperatori().subscribe(operatoriData => {
      this.operatori = operatoriData;
      this.ngxUiLoader.hide()
      this.subscription = timer(0, 10000).pipe(
        startWith(0),
        switchMap(() => this.operatoriService.getStatoOperatori())
      ).subscribe((statiOperatori: StatoOperatore[]) => {
          this.operatori.forEach(o => {
            const stato = statiOperatori.find(e => e.codenumber == (o.codice + ''));
            o.stato = stato;
            if (stato) {
              o.style = statiOperatoriStyle.find(e => e.stati.includes(stato.state['value']));
            }
          });
          this.operatori = this.operatori.filter(e => e.stato);
          
          this.operatori = [
            ...this.operatori.filter(o => STATO_OCCUPATO.stati.includes(o.stato.state['value'])),
            ...this.operatori.filter(o => STATO_PRENOTATA.stati.includes(o.stato.state['value'])),
            ...this.operatori.filter(o => STATO_CHIAMA.stati.includes(o.stato.state['value'])),
            ...this.operatori.filter(o => STATO_PAUSA.stati.includes(o.stato.state['value'])),
            ...this.operatori.filter(o => STATO_OFFLINE.stati.includes(o.stato.state['value'])),
          ];
          if (!this.loadComplete === true) {
            const operatoriOffline = this.operatori.filter(o => STATO_OFFLINE.stati.includes(o.stato.state['value']));
            const operatoriNonOffline = this.operatori.filter(o => !STATO_OFFLINE.stati.includes(o.stato.state['value']));
            //shuffle(operatoriNonOffline);
            this.operatori = [...operatoriNonOffline, ...operatoriOffline];
            this.loadComplete = true;
          }
        });

    });
    this.subEventoIndietro = this.navigationService.eventoIndietro.subscribe(x => {
      //alert(this.mostraPannelloChiamata)
      if (this.mostraPannelloChiamata == true) {
        this.mostraPannelloChiamata = false
      } else {
        this.router.navigate(['/']);
      }
    })
  }

  subEventoIndietro: Subscription

  goBack() {
    if (this.mostraPannelloChiamata == true) {
      this.mostraPannelloChiamata = false;
    } else {
      //TOrna home
    }
  }

  chiama(operatore) {
    const style: StatoOperatoreStyle = operatore.style;
    if (style.callBtnEnabled === true) {
      setTimeout(() => {
        this.mostraPannelloChiamata = true;
      }, 250);
    }
  }

  dimissPanel() {
    this.mostraPannelloChiamata = false;
  }

  
  naviga(pagina) {
    this.router.navigate([pagina]);
  }

  //  makeCall(num: string) {
  //    num = num.replace('.', '');
  //    this.callNumber.callNumber(num, true).then(e => {
  //    }).catch(e => {
  //      console.log('error making call', e);
  //    });
  //  }

}

function shuffle(arra1) {
  let ctr = arra1.length, temp, index;
  while (ctr > 0) {
    index = Math.floor(Math.random() * ctr);
    ctr--;
    temp = arra1[ctr];
    arra1[ctr] = arra1[index];
    arra1[index] = temp;
  }
  return arra1;
}
