import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { SegnoZodiacale } from 'src/app/model/segno-zodiacale';
import { NavigationService } from 'src/app/service/navigation.service';


interface Esito {
  maxValue: number;
  minValue: number;
  messages: string[];
  classes: string;
}

@Component({
  selector: 'app-amore',
  templateUrl: './amore.component.html',
  styleUrls: ['./amore.component.scss']
})
export class AmoreComponent implements OnInit, OnDestroy {

  esiti: Esito[] = [
    {
      maxValue: 100,
      minValue: 85,
      messages: [
        'L\'affinità è molto alta!',
        'Sembra proprio che sia la tua anima gemella!',
      ],
      classes: 'animated heartBeat',
    }, {
      maxValue: 84,
      minValue: 70,
      messages: [
        'L\'affinità è alta!',
      ],
      classes: 'animated tada',
    }, {
      maxValue: 69,
      minValue: 55,
      messages: [
        'L\'affinità è nella media',
      ],
      classes: 'animated flipInX',
    }, {
      maxValue: 54,
      minValue: 45,
      messages: [
        'L\'affinità è bassa',
      ],
      classes: 'animated fadeIn slow',
    }, {
      maxValue: 44,
      minValue: 0,
      messages: [
        'L\'affinità è molto bassa',
      ],
      classes: 'animated fadeInUp slow',
    }
  ];

  segnoLui: SegnoZodiacale;
  segnoLei: SegnoZodiacale;
  animationInterval = null;

  fraseEsito = '';

  backButtonSub: Subscription;
  backButtonSub2: Subscription;

  constructor(private navigationService: NavigationService) { }


  mostraRisultato = false;

  percentuale;
  percentualeMostrata = 0;

  random(seed) {
    const x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
  }

  calcola() {
    const a = this.segnoLui.zodiacSignId;
    const b = this.segnoLei.zodiacSignId;
    // tslint:disable-next-line: radix
    const num = 100 * this.random(a + b);

    this.percentuale = num;

    setTimeout(() => {
      this.fraseEsito = null;
      this.percentualeMostrata = 0;
      this.mostraRisultato = true;

      this.animationInterval = setInterval(() => {
        if (this.percentualeMostrata < this.percentuale) {
          this.percentualeMostrata++;
        } else {
          this.fraseEsito = this.getStyle().message;
          clearInterval(this.animationInterval);
        }
      }, 10);

    }, 250);


  }

  close() {
    this.mostraRisultato = false;
  }

  getStyle() {
    const esito: Esito = this.esiti.find(e => this.percentuale >= e.minValue && this.percentuale <= e.maxValue);
    return {
      message: esito.messages[Math.floor(Math.random() * esito.messages.length)],
      classes: esito.classes,
    };
  }

  subEventoIndietro: Subscription
  
  ngOnDestroy() {
    this.subEventoIndietro.unsubscribe()
  }

  ngOnInit(): void {
    this.subEventoIndietro = this.navigationService.eventoIndietro.subscribe(x => {

      if (this.mostraRisultato == true) {
        this.mostraRisultato = false
      } else {
        this.navigationService.goToHome()
      }
    })
  }


  signLuiChange(sign) {
    this.segnoLui = sign;
  }

  signLeiChange(sign) {
    this.segnoLei = sign;
  }
}
