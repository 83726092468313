import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TurniOperatore } from '../model/turni-operatore';

@Injectable({
  providedIn: 'root'
})
export class TurniService {

  constructor(private client:HttpClient) { }

  getTurni() {
    //il codice qui dentro viene eseguito quando viene chiamato il metodo
    return this.client.get<TurniOperatore[]>(environment.restEndpoint + '/app/turni');
  }
  
}
