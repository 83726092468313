import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { User } from '../model/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpClient: HttpClient) { }

  public register(data: User) {
    return this.httpClient.post<any>(environment.restEndpoint + '/app/user/register', data);
  }

  public saveToken(token) {
    return this.httpClient.post<any>(environment.restEndpoint + '/app/user/saveToken', {token} );
  }

}