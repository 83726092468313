import { Component, ViewChild, NgZone, OnInit } from '@angular/core';
import { SegnoZodiacale } from 'src/app/model/segno-zodiacale';
import { OroscopoService } from 'src/app/service/oroscopo.service';
import { UserService } from 'src/app/service/user.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-registrazione',
  templateUrl: './registrazione.component.html',
  styleUrls: ['./registrazione.component.scss']
})
export class RegistrazioneComponent implements OnInit {

  flgSegnoSelezionato = false;

  segnoByDataNascita() {
    const date = moment(this.dataNascita.value);
    this.flgSegnoSelezionato = date.isValid()
    if (date.isValid() === false) {
      return this.segniZodiacali[0];
    }

    const truncatedBirt = moment(date.format('DD MM'), 'DD MM');
    const segno = this.segniZodiacali.find(e => {
      const truncatedStartDate = moment(e.startDay + ' ' + e.startMonth, 'DD MM');
      const truncatedEndDate = moment(e.endDay + ' ' + e.endMonth, 'DD MM');
      return truncatedBirt.isSameOrAfter(truncatedStartDate) && truncatedBirt.isSameOrBefore(truncatedEndDate);
    });
    if (segno !== undefined) {
      this.segnoSelezionato = segno;
    }

    return segno;
  }

  constructor(
    private cookieService: CookieService,
    private oroscopoService: OroscopoService,
    private userService: UserService,
    public router: Router) {
    this.segniZodiacali = this.oroscopoService.getSegniZodiacali();
  }


  @ViewChild('datePicker', { static: false }) datePicker;
  informativa = new FormControl(false);

  dataNascita: FormControl = new FormControl('', Validators.required);

  email: FormControl = new FormControl('', [
    Validators.maxLength(70),
    Validators.minLength(10),
    Validators.pattern('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$'),
    Validators.required
  ]);

  telefono: FormControl = new FormControl('', [
    Validators.minLength(9),
    Validators.maxLength(12),
    Validators.pattern('[0-9]+')
  ]);

  registrationForm: FormGroup = new FormGroup({
    dataNascita: this.dataNascita,
    email: this.email,
    informativa: this.informativa
    // telefono: this.telefono
  });

  segnoSelezionato: SegnoZodiacale = null;
  segniZodiacali: SegnoZodiacale[] = [];

  ngOnInit() {

    const date = moment(this.dataNascita.value);
    if (date.isValid() === false) {
      return this.segniZodiacali[0];
    }

    const truncatedBirt = moment(date.format('DD MM'), 'DD MM');
    const segno = this.segniZodiacali.find(e => {
      const truncatedStartDate = moment(e.startDay + ' ' + e.startMonth, 'DD MM');
      const truncatedEndDate = moment(e.endDay + ' ' + e.endMonth, 'DD MM');
      return truncatedBirt.isSameOrAfter(truncatedStartDate) && truncatedBirt.isSameOrBefore(truncatedEndDate);
    });
    if (segno !== undefined) {
      this.segnoSelezionato = segno;
    }

    return segno;
  }

  dateChanged(event) {
    this.segnoByDataNascita()
  }

  register() {
    const zodiacSignId = this.segnoSelezionato.zodiacSignId;
    this.userService.register(this.registrationForm.value).subscribe(esito => {
      this.cookieService.set('zodiacSignId', zodiacSignId + '');
      this.cookieService.set('primo_avvio', 'registrato');
      this.router.navigate(['/'])
    }); return true;
  }
}