import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationMenuItems } from 'src/app/model/menu-items';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

  constructor(public router: Router) { }

  navigationMenuItems = NavigationMenuItems;
  showFullscreenMenu = false;

  ngOnInit(): void {
  }

  showFullScreenMenu() {
    this.showFullscreenMenu = true;
  }
  
  naviga(pagina) {
    this.router.navigate([pagina]);
  }


}
