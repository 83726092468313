import { Component, OnInit } from '@angular/core';
import { Articolo } from 'src/app/model/articolo';
import { ArticoliService } from 'src/app/service/articoli.service';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { TitletagService } from 'src/app/service/titletag.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-articolo',
  templateUrl: './articolo.component.html',
  styleUrls: ['./articolo.component.scss']
})
export class ArticoloComponent implements OnInit {

  articolo: Articolo

  endpoint = environment.restEndpoint;

  constructor(
    private articoliService: ArticoliService,
    private activedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    public titleTagService: TitletagService) { }

  public getSantizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  ngOnInit(): void {

    this.activedRoute.params.subscribe(e => {

      const slug = e.slug;

      this.articoliService.getArticolo(slug).subscribe((data: Articolo) => {
        
        this.articolo = data;
        this.titleTagService.setTitle(this.articolo.metaTitle);
        this.titleTagService.setSocialMediaTags(
          'https://cartomanziaevolution.it/articolo/' + this.articolo.slug,
          this.articolo.metaTitle,
          this.articolo.metaDescription,
          'https://flexcall.ovh/backend-evolution/app/articoli/content/' + this.articolo.slug + '.jpg');

      })

    });

  }
}
