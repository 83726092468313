import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { OroscopoService } from 'src/app/service/oroscopo.service';
import { SegnoZodiacale } from 'src/app/model/segno-zodiacale';



@Component({
  selector: 'app-zodiac-sign-selector',
  templateUrl: './zodiac-sign-selector.component.html',
  styleUrls: ['./zodiac-sign-selector.component.scss'],
})
export class ZodiacSignSelectorComponent implements OnInit {

  segniZodiacali: SegnoZodiacale[];
  sign: SegnoZodiacale;

  @Output()
  change: EventEmitter<SegnoZodiacale> = new EventEmitter<SegnoZodiacale>();

  constructor(private oroscopoService: OroscopoService) {
  }

  ngOnInit() {
  
    this.segniZodiacali = this.oroscopoService.getSegniZodiacali();
    this.sign = this.segniZodiacali.find(e => e.zodiacSignId === 1);
    this.change.emit(this.sign);
  }

  left() {
    let id = this.sign.zodiacSignId - 1;
    if (id < 1) {
      id = 12;
    }
    this.sign = this.segniZodiacali.find(e => e.zodiacSignId === id);
    this.change.emit(this.sign);
  }

  right() {
    let id = this.sign.zodiacSignId + 1;
    if (id > 12) {
      id = 1;
    }
    this.sign = this.segniZodiacali.find(e => e.zodiacSignId === id);
    this.change.emit(this.sign);
  }

}
