import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  public eventoIndietro: EventEmitter<any> = new EventEmitter<any>();

  goToHome() {
    this.router.navigate(['/'])
  }

  constructor(public router: Router) { }
}
