import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Articolo } from '../model/articolo';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ArticoliService {

  constructor(public httpClient: HttpClient) { }

  getArticolo(id) {
    return this.httpClient.get<Articolo>(environment.restEndpoint + '/app/articoli/dettaglio/' + id);
  }

  getArticoli() {
    return this.httpClient.get<Articolo[]>(environment.restEndpoint + '/app/articoli/lista');
  }

}

