import { Component, OnInit, OnDestroy } from '@angular/core';
import { TurniOperatore } from 'src/app/model/turni-operatore';
import { TurniService } from 'src/app/service/turni.service';
import { NavigationService } from 'src/app/service/navigation.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-turni-cartomanti',
  templateUrl: './turni-cartomanti.component.html',
  styleUrls: ['./turni-cartomanti.component.scss']
})
export class TurniCartomantiComponent implements OnInit, OnDestroy {

  datiNonFiltrati: TurniOperatore[];
  datiFiltati: TurniOperatore[];

  subEventoIndietro: Subscription

  loadComplete = false;
  mostraRisultato = false;

  constructor(private turniService: TurniService,
    private navigationService: NavigationService) { }

  ngOnInit() {
    this.turniService.getTurni().subscribe((data: TurniOperatore[]) => {
      this.datiNonFiltrati = data;
      this.datiFiltati = this.datiNonFiltrati;
      this.loadComplete = true;
    });
    this.subEventoIndietro = this.navigationService.eventoIndietro.subscribe(x => {
      if (this.mostraRisultato == true) {
        this.mostraRisultato = false
      } else {
        this.navigationService.goToHome()
      }
    });
  }
  filtra(event) {
    const valore = event.target.value;
    this.datiFiltati = this.datiNonFiltrati.filter(
      turno => turno.nickName.toString().toLowerCase().includes(valore.toString().toLowerCase())
    );
  }
  ngOnDestroy() {
    this.subEventoIndietro.unsubscribe()
   }
}
