import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';


@Injectable({
    providedIn: 'root',
})
export class ErrorHandlingService {

    constructor(
        private router: Router, private ngxUiLoader: NgxSpinnerService) { }


    public handleError(event) {
        this.ngxUiLoader.hide();
        const error = event.status;
        if (error === 404) {
            this.router.navigate(['home'])   
        } 
        
        // Se l'utente è registrato in caso di errore torna alla home
        // this.appSettings.getSetting('registred').then(isRegistred => {
        //     this.zone.run(async () => {
        //         await this.navCtrl.navigateRoot('/home');
        //     });
        // }).catch((e) => { });
    }

}
