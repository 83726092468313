import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OroscopoEntry } from '../model/oroscopo-entry';
import { environment } from 'src/environments/environment';
import { SegnoZodiacale } from '../model/segno-zodiacale';

@Injectable({
  providedIn: 'root'
})
export class OroscopoService {

  public segniZodiacali: SegnoZodiacale[] = [
    {
      zodiacSignId: 11, zodiacSignName: 'Acquario',
      startDay: 21, startMonth: 1,
      endDay: 19, endMonth: 2,
      image: 'assets/oroscopo/segni_zodiacali/acquario.png'
    },
    {
      zodiacSignId: 12, zodiacSignName: 'Pesci',
      startDay: 20, startMonth: 2,
      endDay: 20, endMonth: 3,
      image: 'assets/oroscopo/segni_zodiacali/pesci.png'
    },
    {
      zodiacSignId: 1, zodiacSignName: 'Ariete',
      startDay: 21, startMonth: 3,
      endDay: 20, endMonth: 4,
      image: 'assets/oroscopo/segni_zodiacali/ariete.png'
    },
    {
      zodiacSignId: 2, zodiacSignName: 'Toro',
      startDay: 21, startMonth: 4,
      endDay: 20, endMonth: 5,
      image: 'assets/oroscopo/segni_zodiacali/toro.png'
    },
    {
      zodiacSignId: 5, zodiacSignName: 'Gemelli',
      startDay: 21, startMonth: 5,
      endDay: 21, endMonth: 6,
      image: 'assets/oroscopo/segni_zodiacali/gemelli.png'
    },
    {
      zodiacSignId: 6, zodiacSignName: 'Cancro',
      startDay: 22, startMonth: 6,
      endDay: 22, endMonth: 7,
      image: 'assets/oroscopo/segni_zodiacali/cancro.png'
    },
    {
      zodiacSignId: 9, zodiacSignName: 'Leone',
      startDay: 23, startMonth: 7,
      endDay: 23, endMonth: 8,
      image: 'assets/oroscopo/segni_zodiacali/leone.png'
    },
    {
      zodiacSignId: 10, zodiacSignName: 'Vergine',
      startDay: 24, startMonth: 8,
      endDay: 22, endMonth: 9,
      image: 'assets/oroscopo/segni_zodiacali/vergine.png'
    },
    {
      zodiacSignId: 3, zodiacSignName: 'Bilancia',
      startDay: 23, startMonth: 9,
      endDay: 22, endMonth: 10,
      image: 'assets/oroscopo/segni_zodiacali/bilancia.png'
    },
    {
      zodiacSignId: 4, zodiacSignName: 'Scorpione',
      startDay: 23, startMonth: 10,
      endDay: 22, endMonth: 11,
      image: 'assets/oroscopo/segni_zodiacali/scorpione.png'
    },
    {
      zodiacSignId: 7, zodiacSignName: 'Saggittario',
      startDay: 23, startMonth: 11,
      endDay: 21, endMonth: 12,
      image: 'assets/oroscopo/segni_zodiacali/saggittario.png'
    },
    {
      zodiacSignId: 8, zodiacSignName: 'Capricorno',
      startDay: 22, startMonth: 12,
      endDay: 20, endMonth: 1,
      image: 'assets/oroscopo/segni_zodiacali/capricorno.png'
    },
  ];


  public getSegniZodiacali() {
    return this.segniZodiacali;
  }

  constructor(public httpClient: HttpClient) { }

  public getOroscopoEntries() {
    return this.httpClient.get<OroscopoEntry[]>(environment.restEndpoint + '/app/oroscopo/get');
  }

}
