import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavigationService } from 'src/app/service/navigation.service';

@Component({
  selector: 'app-paypal',
  templateUrl: './paypal.component.html',
  styleUrls: ['./paypal.component.scss']
})
export class PaypalComponent implements OnInit {

  constructor(private navigationService: NavigationService) { }

  mostraRisultato = false;

  cellulare = '';
  ripetiCellulare;
  importo = '0';
  privacy = false;

  ngOnInit() {
    this.subEventoIndietro = this.navigationService.eventoIndietro.subscribe(x => {
      if (this.mostraRisultato == true) {
        this.mostraRisultato = false
      } else {
        this.navigationService.goToHome()
      }
    })
  }
  subEventoIndietro: Subscription

  datiCorretti() {

    let numeroCellulareImpostato = this.cellulare != ''
    let numeroCellulareValido = this.isValid(this.cellulare)
    let numeroCellulareRipetutoCorrettamente = this.ripetiCellulare == this.cellulare
    let importoSelezionato = this.importo != '0'

    return (numeroCellulareImpostato && numeroCellulareRipetutoCorrettamente && importoSelezionato && numeroCellulareValido && this.privacy)
  }

  

  isValid(p) {
    return p.length == 9 || p.length == 10
  }
}
