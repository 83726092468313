<div class="container-fluid" style="padding-top: 1rem;">
    <div class="row text-center">
        <div class="col-lg-6">
            <h2>Come funziona?</h2>

            <h6>1) Scegli il taglio di ricarica che fa per te</h6>

            <h6>2) Inserisci il tuo numero di telefono</h6>

            <h6>3) Ripeti il numero</h6>

            <h6>4) Clicca sul tasto “Ricarica Adesso”</h6>
            <br>
            <p>Una volta ricaricato, <strong>potrai parlare senza limiti di tempo al numero</strong> <a
                    style="color: rgb(52, 150, 160); font-weight: 600;" href="tel:06404010"> 06.40.10.10</a> ed
                <strong>utilizzare il
                    credito quando vorrai</strong>, ogni volta che ne avrai bisogno. Il servizio è da
                intendersi
                iva compresa, tutte le transazioni sono sicure e protette.</p>

        </div>

        <div class="col-lg-6 text-center">
            <form ngNoForm method="POST"
                action="https://customers.b4tlc.it/application/B4tlc/index.php?r=pr_cc/CCrecharge4">
                <input type="hidden" name="actiontype" value="tran" />
                <input type="hidden" name="ddi" value="06401010" />
                <input type="hidden" name="PaymentOption" value="paypal" />
                <input type="hidden" name="email" value="stefano640@live.it" />

                <div class="form-row">
                    <div class="col">
                        <h6>Cellulare:</h6>
                        <input [(ngModel)]="cellulare" type="text" class="form-control" placeholder="Cell..."
                            name="cli" />
                    </div>
                    <div class="col">
                        <h6>Ripeti Cellulare:</h6>
                        <input [(ngModel)]="ripetiCellulare" type="text" class="form-control" placeholder="..." />
                    </div>
                </div>
                <hr>
                <h6>Scegli il Taglio da Ricaricare:</h6>
                <select [(ngModel)]="importo" name="amount" class="custom-select">
                    <option value="0" selected>Seleziona un Importo</option>
                    <option value="10">€ 10,00 (min. 9)</option>
                    <option value="20">€ 20,00 (min. 18)</option>
                    <option value="30">€ 30,00 (min. 28)</option>
                    <option value="50">€ 50,00 (min. 50)</option>
                    <option value="100">€ 100,00 (min. 110)</option>
                    <option value="150">€ 150,00 (min. 170)</option>
                    <option value="200">€ 200,00 (min. 240)</option>
                </select>
                <div class="form-group">
                    <div class="form-check" style="margin-top: 1rem;">
                        <input [(ngModel)]="privacy" class="form-check-input" type="checkbox" value=""
                            id="invalidCheck2" required>
                        <label class="form-check-label" style="color: white;" for="invalidCheck2">
                            <small>Acconsento al trattamento dei miei dati personali secondo
                                l’informativa <a style="color: white;"
                                    href="https://www.iubenda.com/privacy-policy/29659667">privacy</a>.</small>
                        </label>
                    </div>
                </div>
                <button class="btn btn-success" type="submit" [disabled]="!datiCorretti()"><i class="fa fa-paypal"></i>
                    RICARICA CONTO</button>

            </form>
        </div>
    </div>
</div>