export const NavigationMenuItems = [
    {   
        icon:'fa-phone',
        title: 'Servizio Cortesia',
        link: 'tel:0695540955',
        target: '_self',
        isActive: (router) => router.url === '/'
    },
    {
        icon:'fa-phone',
        title: 'Chiama Direttamente',
        link: 'tel:899775015',
        target: '_self',
        isActive: (router) => router.url === '/'
    },
    {
        icon:'fa-credit-card',
        title: 'Chiama con Carta',
        link: 'tel:0695544491',
        target: '_self',
        isActive: (router) => router.url === '/'
    },
    {
        icon:'fa-paypal',
        title: 'Ricarica conto PayPal',
        link: '/paypal',
        target: '_self',
        isActive: (router) => router.url === '/'
    },
];