<div class="zodiac-sign-name">{{sign.zodiacSignName}}</div>
<div style="display: flex !important; align-items: center; flex-direction: row;">

    <div style="flex-grow: 0.05;"></div> 
    <a (click)="left()" style="flex-grow: 0.1;" class="btn btn-custom btn-success">
        <i class="fa fa-chevron-left" style="color:#dedede; font-size: 8vw; margin-top: 4px; margin-right: 4px;"></i>
    </a>
    <div style="flex-grow: 0.05;"></div> 

    <div style="flex-grow: 0.6; flex-basis: 0;"><img src='{{sign.image}}' class="img-style" /></div>

    <div style="flex-grow: 0.05;"></div> 
    <a (click)="left()" style="flex-grow: 0.1;" class="btn btn-custom btn-success">
        <i class="fa fa-chevron-right" style="color:#dedede; font-size: 8vw; margin-top: 4px; margin-left: 4px;"></i>
    </a>
    <div style="flex-grow: 0.05;"></div> 

</div>