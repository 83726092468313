
<!---->
<div #container>


    <div *ngIf="!loadComplete" style="margin-top: 2rem;">

    </div>

    <div *ngIf="loadComplete" class="statoOperatoriContainer"
        >
        <ng-container *ngFor="let operatore of getElencoFiltrato()">
            <ng-container *ngIf="!deviceService.isMobile()">
                <div class="card m-1">
                    <div class="row">
                        <div class="text-center">
                            <div class="img-cont-1">
                                <img src="/assets/logo.png" width="60" height="60" alt="logo" class="">
                            </div>
                        </div>
                        <div class="col">
                            <div class="row">
                                <div class="col-6" style="min-height: 60px;">
                                    <strong (click)="gotoOperatore(operatore)" class="operatore-name">
                                        <div *ngIf="operatore.isNew == true" class="operatore-new">NEW </div>
                                        {{operatore.firstname}} {{operatore.codenumber}}
                                    </strong>
                                </div>
                                <div class="col-6">
                                    <div class="row text-center">
                                        <div class="col-12">
                                            <strong>{{operatore.turni}}</strong>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-lg-12">

                                    <div class="box-container">
                                        <div class="box-stato" style="font-size: 14px; padding: 0px;">
                                        </div>

                                        <div class="box-filler"></div>

                                        <div class="box-icone">
                                            <div class="serv-icons">
                                                <div class="icons-s" style="width: 84px;">
                                                    <button class="modal-btn"> <i
                                                            class="fa fa-phone" aria-hidden="true" data-toggle="modal"
                                                            data-target="#exampleModal"></i></button>

                                                    <i (click)="gotoOperatore(operatore)" class="fa fa-user"
                                                        aria-hidden="true" style="cursor: pointer;"></i>

                                                    <i (click)="gotoOperatore(operatore)" class="fa fa-clock-o"
                                                        aria-hidden="true" style="cursor: pointer;"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            
            <ng-container *ngIf="deviceService.isMobile()">
                <div  class="card-2 m-1 text-center">
                    <div class="row">
                        <div class=" col-lg-12 text-center">
                            <div class="img-cont-1">
                                <img src="/assets/logo.png" width="60" height="60" alt="logo" class="">
                            </div>
                        </div>


                        <div class="row" style="padding:14px;">
                            <div class="col-12" style="min-height: 20px;">
                                <strong (click)="gotoOperatore(operatore)" class="operatore-name">
                                    <div *ngIf="operatore.isNew == true" class="operatore-new">NEW </div>
                                    {{operatore.firstname}} {{operatore.codenumber}}
                                </strong>
                            </div>
                            <hr>
                            <div class="col-12">
                                <b style="font-size: 14px; color: black; ">Turno:</b>
                            </div>
                            <div class="col-12">
                                <strong>{{operatore.turni}}</strong>
                            </div>

                            <div class="col-lg-12 text-center">
                                <div class="box-container">
                                    <div class="box-stato" style="font-size: 12px; padding: 0px; margin-top: 10px;">

                                    </div>
                                </div>
                                <div class="box-filler"></div>
                                <div class="col-lg-12">
                                    <div class="box-icone" style="margin-top: 10px;">
                                        <div class="serv-icons">
                                            <div class="icons-s" style="width: 84px;">
                                                <button class="modal-btn"> <i
                                                        class="fa fa-phone" aria-hidden="true" data-toggle="modal"
                                                        data-target="#exampleModal"></i></button>

                                                <i (click)="gotoOperatore(operatore)"
                                                    class="fa fa-user" aria-hidden="true" style="cursor: pointer;"></i>

                                                <i (click)="gotoOperatore(operatore)" 
                                                    class="fa fa-clock-o" aria-hidden="true"
                                                    style="cursor: pointer;"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

        </ng-container>

        <div *ngFor="let e of numberReturn(paddingColonne); let i = index" class="card-2 m-1" style="border: none;">
        </div>

    </div>
</div>