<div class="container-fluid animate__animated animate__fadeIn delay-500ms">

    <div class="card-custom">
        <div class="card-header">Info e Costi:</div>
        <div class="card-content">
            <div class="row text-center">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-12">
                            <div class="Cortesia">
                                <p style="color: white; font-size: 20px; font-weight: 600;">Servizio Cortesia - 06.955.40.955</p>
                                <p style="color: white;"><strong>€1,50/minuto</strong> </p>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="Carta">
                                <p style="color: white; font-size: 20px; font-weight: 600;">Chiama con Carta - 06.955.44.491</p>
                                <p style="color: white;"><strong>€1,20/minuto</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12" style="border-top: 1px solid white; margin-left:20%; margin-right: 20%;"></div>
                <div class="col-lg-12">
                    <p style="color: white; font-size: 24px; font-weight: 600; margin-top: 0.5rem;">Costo 899.77.50.15</p>
                </div>
                <div class="col-lg-12">
                    <div *ngFor="let prezzo of prezzi" class="row" style="margin-bottom: 8px">
                        <div class="col-lg-4 nome-custom">
                            <div class="row">
                                <div class="col-12">
                                    <img src="{{prezzo.operatoreImg}}" class="img-style">
                                </div>
                            </div>


                        </div>
                        <div class="col-lg-8" style="font-size: .9em;">
                            <div class="row">
                                <div class="col-lg-12 prezzo">Scatto alla risposta €{{prezzo.scattoRisposta}}</div>
                                <div class="col-lg-12 prezzo">Importo al minuto €{{prezzo.importoMinuto}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 info" style="text-align: center; margin-top: 12px; color: white;">
                    <hr>
                    Tutti i costi sono da ritenersi <strong>iva compresa</strong>
                </div>
            </div>
        </div>
    </div>
</div>