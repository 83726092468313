import { Component, OnInit, Input, HostListener, ViewChild, ElementRef, PLATFORM_ID, Inject } from '@angular/core';
import { OperatoriService } from 'src/app/service/operatori.service';
import { StatoOperatore } from 'src/app/model/stato-operatore';
import { timer } from 'rxjs';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

export const statoOperatorePrenotato: string[] = ['OutBound'];
export const statoOperatoreOccupato: string[] = ['Answered', 'Call transfer', 'After Call Working'];
export const statoOperatoreLibero: string[] = ['Idle'];
export const statoOperatorePausa: string[] = ['Busy Channel', 'Pause'];
export const statoOperatoreOffline: string[] = ['Logoff'];

@Component({
  selector: 'app-stato-operatori',
  templateUrl: './stato-operatori.component.html',
  styleUrls: ['./stato-operatori.component.scss']
})
export class StatoOperatoriComponent implements OnInit {

  statoPrenotato = statoOperatorePrenotato;
  statoOccupato = statoOperatoreOccupato;
  statoLibero = statoOperatoreLibero;
  statoPausa = statoOperatorePausa;
  statoOffline = statoOperatoreOffline;

  @Input() filtro = '';
  @Input() modalita = '';

  loadComplete = false;

  numeroColonne = 1;
  larghezzaMinima = '0px';
  elencoOperatori: StatoOperatore[] = [];

  paddingColonne = 0;

  @ViewChild('container', { static: true }) container: ElementRef;

  numberReturn(length) {
    return new Array(length);
  }

  constructor(
    private operatoriService: OperatoriService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformID: any) { }

  tempoAggiornamentoSecondi = 15;

  getFiltro() {
    return this.filtro.toString().toLowerCase();
  }

  getElencoFiltrato() {
    let data = this.elencoOperatori;

    data = data.filter(
      e => e.firstname.toString().toLowerCase().includes(this.getFiltro())
    );

    if (this.modalita === 'tutti') {

      data = [
        ...data.filter(e => this.statoPrenotato.includes(e.state)),
        ...data.filter(e => this.statoOccupato.includes(e.state)),
        ...data.filter(e => this.statoLibero.includes(e.state)),
        ...data.filter(e => this.statoPausa.includes(e.state)),
        ...data.filter(e => this.statoOffline.includes(e.state))
      ];

    };
    return data;
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformID)) {
      timer(0, 1000 * this.tempoAggiornamentoSecondi).subscribe(() => {
        // Quando si apre il componente
        this.aggiornaNumeroColonne();

        this.operatoriService.getStatoOperatori().subscribe((data: StatoOperatore[]) => {

          this.loadComplete = true;

          if (this.elencoOperatori.length === 0) {
            this.elencoOperatori = data;
          }

        });
      });
    }
  }


  // Quando viene ridimensionata la pagina
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.aggiornaNumeroColonne();
  }

  aggiornaNumeroColonne() {
    this.numeroColonne = Math.floor(this.container.nativeElement.offsetWidth / 264);
    this.larghezzaMinima = (132 * this.numeroColonne) + 'px';
  }

  gotoOperatore(operatore: StatoOperatore) {
    this.router.navigate(['/cartomante/' + operatore.pseudonym]);
  }

  getCardStyle(statoOperatore: StatoOperatore) {
    if (statoOperatoreLibero.includes(statoOperatore.state)) {
      return {
        cardStyle: {
          'border-color': 'rgb(4, 179, 27)'
        },
        buttonText: 'Chiama ora',
        buttonStyle: {
          color: 'rgb(4, 179, 27)',
          'border-width': '1px',
          'border-radius': '5px',
          'border-style': 'solid'
        }
      };
    } else if (statoOperatoreOccupato.includes(statoOperatore.state)) {
      return {
        cardStyle: {
          'border-color': '#ff0000'

        },
        buttonText: 'Occupato' + (statoOperatore.currentqueue !== '' ? ' con ' + statoOperatore.currentqueue.toString().toLowerCase() : ''),
        buttonStyle: {
          color: 'red',
          'font-size': '12px'
        }
      };
    } else if (statoOperatoreOffline.includes(statoOperatore.state)) {
      return {
        cardStyle: {
          'border-color': '#999'
        },
        buttonText: 'Offline',
        buttonStyle: {
          color: 'grey'
        }
      };
    } else if (statoOperatorePrenotato.includes(statoOperatore.state)) {
      return {
        cardStyle: {
          'border-color': 'rgb(255, 145, 0)'
        },
        buttonText: 'Prenotato',
        buttonStyle: {
          color: 'rgb(255, 145, 0)'
        }
      };
    } else if (statoOperatorePausa.includes(statoOperatore.state)) {
      return {
        cardStyle: {
          'border-color': 'orange'
        },
        buttonText: 'In pausa',
        buttonStyle: {
          color: 'rgb(255, 0, 212)'
        }
      };
    }
  }

}
