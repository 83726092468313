

<div id="navbar-iphone" class="navbar-custom navbar-custom-iphone-2">
    <div class="row text-center">
        <div class="col-4">
            <a href="javascript:void(0)" (click)="navigationService.eventoIndietro.emit()">
                <div class="margins">
                    <div class="first-button"><i class="fa fa-arrow-left"></i></div>
                    <div class="text">
                        <p>Indietro</p>
                    </div>
                </div>
            </a>
        </div>
        <div class="col-4">
            <a href="tel:0695540955" angulartics2On="click" angularticsAction="Chiamata"
            [angularticsCategory]="'Conversione'">
                <div class="margins">
                    <div class="second-button"><i class="fa fa-phone"></i></div>
                    <div class="text">
                        <p>Servizio Cortesia</p>
                    </div>
                </div>
            </a>
        </div>
        <div class="col-4">
            <a href="javascript:void(0)" (click)="naviga('/')">
                <div class="margins">
                    <div class="third-button"><i class="fa fa-home"></i></div>
                    <div class="text">
                        <p>Home</p>
                    </div>
                </div>
            </a>
        </div>
    </div>
</div>

<script>
    var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    document.getElementsByTagName("#navbar-iphone")[0].setAttribute("class", "iOS-device");
</script>

