<div class="container-fluid fluid-custom" *ngIf="mostraPannelloChiamata" style="height: 100%;">
    <div class="pannello-chiamata">
        <div class="card-no-bg">
            <div class="pannello-chiama" style="font-size: 6vw; padding: 5vw;">

                <div class="pannello-chiamata-bg">
                    <h1 style="margin-bottom: 6vw;">Contatta i nostri esperti</h1>
                    <div class="op-wrapper">
                        <div class="action-text">Parla con il Servizio Cortesia</div>
                    </div>
                    <a href="tel:0695540955" class="btn btn-custom" angulartics2On="click" angularticsAction="Chiamata"
                        [angularticsCategory]="'Conversione'"><i class="fa fa-phone"></i> Servizio Cortesia</a>
                    <!--   <div class="op-wrapper call-btn" (click)="makeCall(servizioCortesiaPhone)">
                        <div class="op-ico">
                          <i class="fa fa-phone"></i>
                        </div>
                        <div class="action-text-number">{{servizioCortesiaPhone}}</div>
                    </div> -->


                    <div class="op-wrapper">
                        <div class="action-text">Chiama direttamente</div>
                    </div>

                    <a href="tel:899775015" angulartics2On="click" angularticsAction="Chiamata"
                        [angularticsCategory]="'Conversione'" class="btn btn-custom"><i class="fa fa-phone"></i> Chiama
                        Direttamente</a>
                    <!--  <div class="op-wrapper call-btn" (click)="makeCall(chiamaDirettamentePhone)">
                        <div class="op-ico">
                            <i class="fa fa-phone"></i>
                        </div>
                        <div class="action-text-number">{{chiamaDirettamentePhone}}</div>
                    </div> -->
                    <div class="op-wrapper">
                        <div class="action-text">Oppure</div>
                    </div>

                    <a href="tel:0695544491" angulartics2On="click" angularticsAction="Chiamata"
                        [angularticsCategory]="'Conversione'" class="btn btn-custom"><i class="fa fa-credit-card"></i>
                        Chiama Con Carta</a>
                    <!--  <div class="op-wrapper call-btn" (click)="makeCall(ricaricaConCartaPhone)">
                        <div class="op-ico">
                            <i class="fa fa-menu"></i>
                        </div>
                        <div class="action-text-number">Ricarica con carta</div>
                    </div> -->
                    <div class="op-wrapper">
                        <div class="action-text">Oppure</div>
                    </div>
                    <a (click)="naviga('paypal')" class="btn btn-custom"><i class="fa fa-paypal"></i> Ricarica
                        PayPal</a>

                </div>

            </div>
        </div>
    </div>
</div>

<div class="container-fluid custom-2" *ngIf="!mostraPannelloChiamata">

    <div class="card-custom animate__animated animate__fadeIn delay-500ms" *ngFor="let operatore of operatori">
        <div *ngIf="operatore.stato" style="font-size: 6vw; padding: 1vw;">
            <div class="op-wrapper">
                <div class="op-avatar">
                    <div *ngIf="operatore.avatarBase64"><img src="{{ operatore.avatarBase64 }}" class="img-custom" >
                    </div>
                    <div *ngIf="!operatore.avatarBase64">
                        <i style="color: white;" class="fa fa-user" (click)="gotoOperatore(operatore)"></i>
                    </div>
                </div>
                <div class="op-name" (click)="gotoOperatore(operatore)">
                    <div style="color: white; font-size: 20px;">{{ operatore.nickName }}</div>
                    <div style="font-size: 60%;color: white;">{{ operatore.codice }}</div>
                </div>
                <div>
                    <i class="fa fa-info" style="color: white;font-size: 26px;margin-right: 14px; border: 1px solid white;padding:0.5rem;border-radius: 5px;" (click)="gotoOperatore(operatore)"></i>
                </div>
                <div class="op-action pannello-chiamata-bg" (click)="chiama(operatore)">

                    <span [ngClass]="operatore.style.textClass" class="card-chiamata-custom"
                        style="font-size: 4.5vw; margin-top: .5vw; margin-right: 2vw"
                        [ngStyle]="operatore.style.css">{{operatore.style.description}}</span>
                </div>
            </div>
        </div>
    </div>

</div>