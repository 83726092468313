import { Component, OnInit, OnDestroy } from '@angular/core';
import { OroscopoService } from 'src/app/service/oroscopo.service';
import { Subscription } from 'rxjs';
import { SegnoZodiacale } from 'src/app/model/segno-zodiacale';
import { NavigationService } from 'src/app/service/navigation.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-oroscopo',
  templateUrl: './oroscopo.component.html',
  styleUrls: ['./oroscopo.component.scss'],
})
export class OroscopoComponent implements OnInit, OnDestroy {

  mostraRisultato = false; 
  
  subEventoIndietro: Subscription
  
  constructor(
    public navigationService: NavigationService,
    public oroscopoService: OroscopoService,
    public router: Router,) { }

  backButtonSub: Subscription = null;
  backButtonSub2: Subscription;
  segniZodiacali: SegnoZodiacale[] = null;

  ngOnInit() {
    this.segniZodiacali = this.oroscopoService.getSegniZodiacali();
    this.subEventoIndietro = this.navigationService.eventoIndietro.subscribe(x => {
      if (this.mostraRisultato == true) {
        this.mostraRisultato = false
      } else {
        this.navigationService.goToHome()
      }
    })
  }


  ngOnDestroy() {
    if (this.backButtonSub) {
      this.backButtonSub.unsubscribe();
    }
    if (this.backButtonSub2) {
      this.backButtonSub2.unsubscribe();
    }
    this.subEventoIndietro.unsubscribe()
  }

  navigateToDetail(id) {
    this.router.navigate(['/oroscopo-detail'], { queryParams: { id: id } });

  }

}
