<div class="container-fluid">
    <div class="oroscopo-card-container animate__animated animate__fadeIn delay-500ms" style="margin-bottom: 8vh">
        <div class="oroscopo-card card-custom" *ngFor="let segnoZodiacale of segniZodiacali"
            (click)="navigateToDetail(segnoZodiacale.zodiacSignId)">
            <div class="card-content">
                <div style="display: flex; flex-direction: column;">
                <div class="zodiac-sign-name">{{ segnoZodiacale.zodiacSignName }}</div>
                
                    <img class="oroscopo-card-image" src='{{segnoZodiacale.image}}' />
                </div>
            </div>
        </div>
    </div>
</div>