<div class="container-fluid" style="padding-bottom: 5rem;">

    <div class="card">

        <div class="row">
            <div class="col-12">
                <h1 style="font-size: 32px;">{{articolo?.titolo}}</h1>
            </div>
        </div>

        <div class="row">
            <div *ngIf="articolo.fotoBase64 != null" class="col-12">
                <div style="display: flex; justify-content: center;" class="mb-4 mt-4">
                    <img alt="{{articolo.fotoAltText}}" style="height: 172px!important;" class="img-fluid"
                        [src]="getSantizeUrl(endpoint + '/app/articoli/content/' + articolo.slug + '.jpg')" />
                </div>
            </div>
            <div class="col-12">
                <div *ngIf="articolo" [innerHTML]="articolo.contenuto"></div>
            </div>
        </div>

    </div>

</div>