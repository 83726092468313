<header *ngIf="!mostraRisultato">

</header>

<div class="container-fluid" *ngIf="mostraRisultato" style="height: auto;">
    <div style="height: 100%; display: flex; flex-direction: row; align-items: center; justify-content: center;">
        <div class="card-no-bg">
            <div class="pannello-esito" style="font-size: 6vw; padding: 5vw;">
                <div style="padding-top: 2vw!important; padding-bottom: 2vw!important;">
                    <div style="text-align: center; color: white;">
                        Affinità di coppia
                    </div>
                </div>
                <div>
                    <div style="font-size: 20vw; text-align: center; color: white;">
                        {{ percentualeMostrata }}%
                    </div>
                    <div *ngIf="fraseEsito != null" [ngClass]="getStyle().classes"
                        style="font-size: 5vw; text-align: center; color: white;">
                        {{ fraseEsito }}
                    </div>
                </div>
                <div class="btn-confirm-wr">
                    <a (click)="close()" class="btn btn-custom btn-confirm">
                        Chiudi
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="container-fluid" style="padding: 2rem;" *ngIf="!mostraRisultato">
    <div class="ion-card">
        <div style="padding-top: 8vw; padding-bottom: 8vw;">
            <div style="text-align: center; color: white;">
                Affinità di coppia
            </div>
        </div>
        <div>
            <div style="text-align: center; color: white; font-size: 20px;">
                Segno zodiacale di lui:
            </div>
            <div class="sign-selector-lui">
                <app-zodiac-sign-selector (change)="signLuiChange($event)"></app-zodiac-sign-selector>
            </div>
            <div style="text-align: center; color: white; font-size: 24px;">
                Segno zodiacale di lei:
            </div>
            <div class="sign-selector-lei">
                <app-zodiac-sign-selector (change)="signLeiChange($event)"></app-zodiac-sign-selector>
            </div>
        </div>
    </div>
    <div class="btn-confirm-wr">
        <a (click)="calcola()" class="btn btn-custom btn-success">
            <i class="fa fa-heart"></i> Scopri
        </a>
    </div>
</div>