import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { NavigationService } from 'src/app/service/navigation.service';
import { Subscription } from 'rxjs';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  constructor(public router: Router,
    public navigationService: NavigationService,
    private cookieService: CookieService) { }

  subEventoIndietro: Subscription

  naviga(pagina) {
    this.router.navigate([pagina]);
  }

  ngOnInit(): void {
    //  const cookieValue =  this.cookieService.get('primo_avvio');
    //  console.log(cookieValue)
    //  if ( cookieValue != 'registrato') { 
    //    this.naviga('registrazione');
    //  }
    //  
    this.subEventoIndietro = this.navigationService.eventoIndietro.subscribe(x => {
    })


  }
  ngOnDestroy() {
    this.subEventoIndietro.unsubscribe()
  }


  isInPage(pagina) {
    return this.router.url === '/' + pagina;
  }
}
