<div class="container-fluid">
    <div *ngIf="innerWidth <= 768" id="tableTurniContainer"
      class="gem-table gem-table-responsive gem-table-style-3 row-headers"
      style="font-weight: 400; text-transform: uppercase;">
      <div class="container">
        <table *ngIf="turniOperatori.length != 0">
          <tr>
            <td class="giorni-2 text-center" colspan="2">Lunedì</td>
          </tr>
          <tr *ngFor="let operatore of turniOperatori">
            <td class="nickname-2">{{operatore.nickName}}</td>
            <td class="lunedi-2" [innerHTML]="getTurniGiorno(operatore.turni, 1)"></td>
          </tr>
          <tr>
            <td colspan="2"></td>
          </tr>
          <tr>
            <td class="giorni-2 text-center" colspan="2">Martedì</td>
          </tr>
          <tr *ngFor="let operatore of turniOperatori">
            <td class="nickname-2">{{operatore.nickName}}</td>
            <td class="martedi-2" [innerHTML]="getTurniGiorno(operatore.turni, 2)"></td>
          </tr>
          <tr>
            <td colspan="2"></td>
          </tr>
          <tr>
            <td class="giorni-2 text-center" colspan="2">Mercoledì</td>
          </tr>
          <tr *ngFor="let operatore of turniOperatori">
            <td class="nickname-2">{{operatore.nickName}}</td>
            <td class="mercoledi-2" [innerHTML]="getTurniGiorno(operatore.turni, 3)"></td>
          </tr>
          <tr>
            <td colspan="2"></td>
          </tr>
          <tr>
            <td class="giorni-2 text-center" colspan="2">Giovedì</td>
          </tr>
          <tr *ngFor="let operatore of turniOperatori">
            <td class="nickname-2">{{operatore.nickName}}</td>
            <td class="giovedi-2" [innerHTML]="getTurniGiorno(operatore.turni, 4)"></td>
          </tr>
          <tr>
            <td colspan="2"></td>
          </tr>
          <tr>
            <td class="giorni-2 text-center" colspan="2">Venerdì</td>
          </tr>
          <tr *ngFor="let operatore of turniOperatori">
            <td class="nickname-2">{{operatore.nickName}}</td>
            <td class="venerdi-2" [innerHTML]="getTurniGiorno(operatore.turni, 5)"></td>
          </tr>
          <tr>
            <td colspan="2"></td>
          </tr>
          <tr>
            <td class="giorni-2 text-center" colspan="2">Sabato</td>
          </tr>
          <tr *ngFor="let operatore of turniOperatori">
            <td class="nickname-2">{{operatore.nickName}}</td>
            <td class="sabato-2" [innerHTML]="getTurniGiorno(operatore.turni, 6)"></td>
          </tr>
          <tr>
            <td colspan="2"></td>
          </tr>
          <tr>
            <td class="giorni-2 text-center" colspan="2">Domenica</td>
          </tr>
          <tr *ngFor="let operatore of turniOperatori">
            <td class="nickname-2">{{operatore.nickName}}</td>
            <td class="domenica-2" [innerHTML]="getTurniGiorno(operatore.turni, 7)"></td>
          </tr>
          <tr>
            <td colspan="2"></td>
          </tr>
        </table>
        <div *ngIf="turniOperatori.length == 0" class="alert bg-info m-4" style="text-transform: none !important;">
          {{nessunRisultato}}</div>
      </div>
    </div>
  </div>