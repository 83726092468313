<div class="container-fluid fluid-custom" *ngIf="mostraPannelloChiamata" style="height: 100%;">
    <div class="pannello-chiamata">
        <div class="card-no-bg">
            <div class="pannello-chiama" style="font-size: 6vw; padding: 5vw;">

                <div class="pannello-chiamata-bg">
                    <h1 style="margin-bottom: 6vw;">Contatta i nostri esperti</h1>
                    <div class="op-wrapper">
                        <div class="action-text">Parla con il Servizio Cortesia</div>
                    </div>
                    <a href="tel:0695540955" class="btn btn-custom" angulartics2On="click" angularticsAction="Chiamata"
                        [angularticsCategory]="'Conversione'"><i class="fa fa-phone"></i> Servizio Cortesia</a>
                    <!--   <div class="op-wrapper call-btn" (click)="makeCall(servizioCortesiaPhone)">
                        <div class="op-ico">
                          <i class="fa fa-phone"></i>
                        </div>
                        <div class="action-text-number">{{servizioCortesiaPhone}}</div>
                    </div> -->


                    <div class="op-wrapper">
                        <div class="action-text">Chiama direttamente</div>
                    </div>

                    <a href="tel:899775015" angulartics2On="click" angularticsAction="Chiamata"
                        [angularticsCategory]="'Conversione'" class="btn btn-custom"><i class="fa fa-phone"></i> Chiama
                        Direttamente</a>
                    <!--  <div class="op-wrapper call-btn" (click)="makeCall(chiamaDirettamentePhone)">
                        <div class="op-ico">
                            <i class="fa fa-phone"></i>
                        </div>
                        <div class="action-text-number">{{chiamaDirettamentePhone}}</div>
                    </div> -->
                    <div class="op-wrapper">
                        <div class="action-text">Oppure</div>
                    </div>

                    <a href="tel:0695544491" angulartics2On="click" angularticsAction="Chiamata"
                        [angularticsCategory]="'Conversione'" class="btn btn-custom"><i class="fa fa-credit-card"></i>
                        Chiama Con Carta</a>
                    <!--  <div class="op-wrapper call-btn" (click)="makeCall(ricaricaConCartaPhone)">
                        <div class="op-ico">
                            <i class="fa fa-menu"></i>
                        </div>
                        <div class="action-text-number">Ricarica con carta</div>
                    </div> -->
                    <div class="op-wrapper">
                        <div class="action-text">Oppure</div>
                    </div>
                    <a (click)="naviga('paypal')" class="btn btn-custom"><i class="fa fa-paypal"></i> Ricarica
                        PayPal</a>

                </div>

            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="!mostraPannelloChiamata">
    <div *ngIf="dettaglioOperatore" class="container-fluid"
        style="padding-top: 1rem; padding-bottom: 4rem; background-color: rgba(156, 156, 156, 0.1);">

        <div class="titolo-pag" style="color: white;">
            <h1><strong> Cartomante {{dettaglioOperatore.nickName}}
                    {{dettaglioOperatore.codice}}</strong></h1>
            <hr>
        </div>

        <div class="container">

            <div class="row">
                <div class="col-lg-6">
                    <div class="card-descr" style="color: white;">
                        <h2 style="font-size: 26px;"><strong>Conosci meglio {{dettaglioOperatore.nickName}}
                                Cartomante</strong></h2>
                        <hr>
                        <p [innerHTML]="dettaglioOperatore.descrizione"></p>
                        <hr>
                        <h6>Quando mi trovi <strong>Disponibile</strong>?</h6>
                    </div>
                </div>

                <div *ngIf="statoOperatore" class="col-lg-6" style="color: white;">
                    <div class="card-img-name">
                        <div>
                            <!--NOME-->
                            <div class="row">
                                <div class="col">
                                    <h2>{{dettaglioOperatore.nickName}}</h2>
                                </div>
                            </div>
                            <!-- Immagine piccola 
                        <img src="{{dettaglioOperatore.avatarBase64}}" />
                        -->
                            <!--IMMAGINE-->
                            <div class="row">
                                <div class="col">
                                    <!-- ...oppure immagine grande -->
                                    <img class="stato-operatore-img" style="height: 120px;" [ngStyle]="getStyle()"
                                        src="{{dettaglioOperatore.fotoBase64}}" />
                                </div>
                            </div>
                            <!-- STATUS -->
                            <div class="row">
                                <div class="col">
                                    <hr>
                                    <div class="stato">
                                        <h4>Stato:<strong [ngStyle]="getStyle()"> {{getStato()}}</strong></h4>
                                    </div>
                                    <hr>
                                    <div class="button-st">
                                        <div class="row">

                                            <div class="col-lg-6">
                                                <a href="javascript:void(0)" class="btn btn-success btn-md btn-block"
                                                    (click)="chiama()"><i class="fa fa-phone"></i> CHIAMA ORA!</a>
                                            </div>
                                            <div class="col-lg-6">
                                                <a href="javascript:void(0)" class="btn btn-primary btn-md btn-block"
                                                    href="tel:0695540955"><i class="fa fa-pencil"></i>
                                                    PRENOTA</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" style="padding-top: 1rem;">
                <div class="col-lg-12">
                    <div *ngIf="!loadComplete" style="margin-top: 2rem;">
                        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate"
                            [fullScreen]="true">
                            <p style="color: white; font-weight: 500; font-size: 24px;"> Caricamento... </p>
                        </ngx-spinner>
                    </div>

                    <app-tabella-turni *ngIf="loadComplete" [turniOperatori]="turniOperatore"></app-tabella-turni>
                </div>
            </div>
            <!--   <div class="row">
            <div class="col-lg-6">
                <div class="card-descr">
                    <h4><strong>Cosa dicono di me?</strong></h4>
                    <hr>
                    <p>{{dettaglioOperatore.descrizione}}</p>
                </div>
            </div>
        </div>-->

        </div>
        <div class="container-fluid" style="padding-left: 0px; padding-right: 0px;">
            <div class="card">
                <div class="row" style="padding-top: 1rem;">
                    <div class="col-lg-12">
                        <div *ngIf="loadComplete === true">
                            <app-inserimento-recensione [operatore]="dettaglioOperatore"></app-inserimento-recensione>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div *ngIf="loadComplete === true">
                            <app-recensioni-operatore [operatore]="dettaglioOperatore"></app-recensioni-operatore>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</ng-container>