import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DettaglioOperatore } from '../model/dettaglio-operatore';
import { StatoOperatore } from '../model/stato-operatore';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class OperatoriService {

  constructor(private http: HttpClient) { }

  getDettaglioOperatore(key: string) {
    return this.http.get<DettaglioOperatore>(environment.restEndpoint + '/app/operatori/dettaglio/' + key);
  }

  getStatoOperatori() {
    return this.http.get<StatoOperatore[]>(environment.restEndpoint + '/app/operatori/');
  }

  getStatoOperatore(id) {
    return this.http.get<StatoOperatore[]>(environment.restEndpoint + '/app/operatori/statoOperatore?operatore=' + id);
  }


}