<div class="top-bar fixed-top">
    <div class="menu-top">
        <div class="link-1">
            <a (click)="naviga('cartomanti')" class="btn btn-custom  border-1"><i class="fa fa-user"></i> Cartomanti</a>
        </div>      
        <div class="link-1">
            <a (click)="naviga('turni-cartomanti')" class="btn btn-custom border-2"><i class="fa fa-calendar"></i> Turni</a>
        </div>
        <div class="link-1">
            <a (click)="naviga('oroscopo')" class="btn btn-custom border-3" ><i class="fa fa-star"></i> Oroscopo</a>
        </div>
        <div class="link-4">
            <button (click)="showFullScreenMenu()" class="btn btn-custom" style="font-size: 24px;"><i class="fa fa-phone" style="padding-top: -5px;"></i></button>
        </div>
    </div>
</div>

<app-full-screen-menu *ngIf="showFullscreenMenu" (close)="showFullscreenMenu=false"></app-full-screen-menu>

<!--            <li>
                <a href="" class="menu-top-link" style="color: white;">Turni Cartomanti</a>
            </li>
        <div class="row text-center no-gutters">
        <div class="col-12">
           <a href="javascript:void(0)"><img src="/assets/brand/logo.png" alt="newstart" class="custom"></a>
        </div>
    </div>
-->

