import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home/home.component';
import { AmoreComponent } from './pagine/amore/amore.component';
import { CartomantiComponent } from './pagine/cartomanti/cartomanti.component';
import { InfoPrezziComponent } from './pagine/info-prezzi/info-prezzi.component';
import { OroscopoComponent } from './pagine/oroscopo/oroscopo.component';
import { OroscopoDetailComponent } from './pagine/oroscopo-detail/oroscopo-detail.component';
import { PromozioniComponent } from './pagine/promozioni/promozioni.component';
import { WelcomeComponent } from './pagine/welcome/welcome.component';
import { RegistrazioneComponent } from './registrazione/registrazione.component';
import { TurniCartomantiComponent } from './pagine/turni-cartomanti/turni-cartomanti.component';
import { PaypalComponent } from './pagine/paypal/paypal.component';
import { PaginaCartomanteComponent } from './pagine/pagina-cartomante/pagina-cartomante.component';
import { ArticoloComponent } from './pagine/articolo/articolo.component';
import { ArticoliComponent } from './pagine/articoli/articoli.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'amore', component: AmoreComponent },
  { path: 'cartomanti', component: CartomantiComponent },
  { path: 'info-prezzi', component: InfoPrezziComponent },
  { path: 'oroscopo', component: OroscopoComponent },
  { path: 'oroscopo-detail', component: OroscopoDetailComponent },
  { path: 'welcome', component: WelcomeComponent },
  { path: 'registrazione', component: RegistrazioneComponent },
  { path: 'turni-cartomanti', component: TurniCartomantiComponent },
  { path: 'paypal', component: PaypalComponent },
  { path: 'cartomante/:nickName', component: PaginaCartomanteComponent },
  { path: 'articolo/:slug', component: ArticoloComponent },
  { path: 'articoli', component: ArticoliComponent },
];



@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
