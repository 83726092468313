<div class="container-fluid animate__animated animate__fadeIn delay-500ms">

    <div class="card-no-bg">
        <div class="card-header" style="padding-top: 8vw;">
            <div class="card-title" style="text-align: center; color: white; font-size: 22px;font-weight: 400;">
                Benvenuto su NewStart!
            </div>
            <div class="card-sub" style="text-align: center; color: white;">
                Compila il form per eseguire la registrazione
            </div>
        </div>

        <div style="padding-top: 2vw;">
            <div class="form-card" style="margin: 0 !important;">
                <form class="card-content-2" [formGroup]="registrationForm"
                    style="display: flex; justify-content:center; flex-direction: column; align-items: center;">

                    <div class="card-sign" *ngIf="flgSegnoSelezionato === true" (click)="datePicker.open()">
                        <div class="zodiac-sign-name">{{ segnoByDataNascita().zodiacSignName }}</div>
                        <img class="oroscopo-card-image" style="width: 26vw;" src='{{segnoByDataNascita().image}}' />
                    </div>
                    
                    <div style="margin-bottom: 5vw; margin-top: 1.5rem;">
                        <p style="margin: auto; text-align: center; color: white;">In base alla data di nascita scelta, verrà mostrato il tuo Segno</p>
                        <div class="row">
                            <div class="col-xs-12 col-12 col-md-4 form-group">
                              <input type="text"
                                     placeholder="Data di Nascita..."
                                     class="form-control"
                                     formControlName="dataNascita"
                                     [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                                     (ngModelChange)="dateChanged($event)"
                                     bsDatepicker>
                            </div>
                          </div>
                    </div>

                    <div *ngIf="dataNascita.touched && dataNascita.invalid">
                        <div *ngIf="dataNascita.errors?.required" color="warning">La data di
                            nascita è obbligatoria
                        </div>
                    </div>

                    <div>
                        <input type="email" class="form-control" formControlName="email" placeholder="Inserisci Email">
                    </div>

                    <div *ngIf="email.touched && email.invalid">
                        <div *ngIf="email.errors.required" color="warning">L'
                            indirizzo email è
                            obbligatorio
                        </div>
                        <div *ngIf="email.errors?.pattern || email.errors?.minlength || email.errors?.maxlength"
                            color="warning">L' indirizzo email non è valido</div>
                    </div>

                    <div class="radio-style" style="color: white !important;">
                        <input class="form-check-input" formControlName="informativa" type="checkbox" style="margin-right: 8px;" value="" id="defaultCheck1">
                        <div style="width: 80%; font-size: .75em;">
                                Acconsento al trattamento dei miei dati personali secondo l'<a style="color: white;" href="https://www.iubenda.com/privacy-policy/75529934">informativa privacy</a>.
                            </div>
                    </div>
                    

                </form>
            </div>

            <div style="margin-top: 6vw; width: 100%;">
                <button class="btn btn-custom btn-confirm btn-confirm-wr" [disabled]="!registrationForm.valid || !informativa.value" (click)="register()">
                    <i class="fa fa-check"></i> Conferma
                </button>
            </div>

        </div>
    </div>

</div>