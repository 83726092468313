import { Component, OnInit, Input } from '@angular/core';
import { RecensioniService } from 'src/app/service/recensioni.service';
import { Recensione } from 'src/app/model/recensione';
import { DettaglioOperatore } from 'src/app/model/dettaglio-operatore';

@Component({
  selector: 'app-recensioni-operatore',
  templateUrl: './recensioni-operatore.component.html',
  styleUrls: ['./recensioni-operatore.component.scss']
})
export class RecensioniOperatoreComponent implements OnInit {

  mediaPunteggio = 0;
  totale5Stelle = 0;
  totale4Stelle = 0;
  totale3Stelle = 0;
  totale2Stelle = 0;
  totale1Stelle = 0;
  
  recensioni: Recensione[] = []

  constructor(private recensioniService: RecensioniService) { }

  @Input() operatore: DettaglioOperatore;

  ngOnInit(): void {
    this.recensioniService.getRecensioniOperatore(this.operatore.codice).subscribe(data => {
      this.recensioni = data;

      let sommatoria = 0;

      this.recensioni.forEach(e => {
        if(e.rating == 1) {
          this.totale1Stelle++;
        } else if(e.rating == 2) {
          this.totale2Stelle++;
        } else if(e.rating == 3) {
          this.totale3Stelle++;
        } else if(e.rating == 4) {
          this.totale4Stelle++;
        } else if(e.rating == 5) {
          this.totale5Stelle++;
        }
        sommatoria += e.rating;
      });

      this.mediaPunteggio = sommatoria / this.recensioni.length;

    });
  }

}
